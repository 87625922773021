<template>
  <v-form>
    <p>
      In a few sentences, describe how to handle and use a crucible and its cover. Be sure to
      address the hazards and safety precautions associated with its use.
    </p>
    <s-textarea v-model="inputs.howToUseACrucible"></s-textarea>
    <ai-loading-overlay v-if="isSubmitting" />
  </v-form>
</template>

<script>
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'HowToUseACrucible',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        howToUseACrucible: null,
      },
    };
  },
};
</script>

<style scoped></style>
